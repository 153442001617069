import React from "react";
import { graphql, Link } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";

export const query = graphql`
  {
    sanityProjectsPage {
      pageTitle
    }
    allSanityProject {
      projects: edges {
        project: node {
          id
          title
          slug {
            current
          }
          coverImage {
            asset {
              id
              originalFilename
              url
              metadata {
                palette {
                  dominant {
                    background
                  }
                }
              }
            }
            altText
          }
        }
      }
    }
  }
`;

export default function Projects({
  data: {
    sanityProjectsPage,
    allSanityProject: { projects }
  }
}) {
  return (
    <Layout title="Projects" page="projects" url="/projects">
      <h1 className="font-h1">{sanityProjectsPage.pageTitle}</h1>
      {projects.map(({ project }) => (
        <Link
          key={project.id}
          to={project.slug.current}
          className="project-card"
        >
          {project.title && <h2 className="font-h2">{project.title}</h2>}
          {project.coverImage && (
            <Image
              image={project.coverImage}
              className="project-card___image"
              style={{
                backgroundColor: `${project.coverImage.asset.metadata.palette.dominant.background}`
              }}
            />
          )}
        </Link>
      ))}
    </Layout>
  );
}
